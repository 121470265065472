import "core-js/modules/es.string.repeat.js";
import { useOrderInfo } from "@/Hooks";
export default {
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var showCC = function showCC() {
      console.log('ccccccc'.repeat(100));
    };

    var _useOrderInfo = useOrderInfo(emit),
        carCardImg = _useOrderInfo.carCardImg,
        form = _useOrderInfo.form,
        formEl = _useOrderInfo.formEl,
        rules = _useOrderInfo.rules,
        submit = _useOrderInfo.submit,
        data = _useOrderInfo.data,
        actionUrl = _useOrderInfo.actionUrl,
        successLoad = _useOrderInfo.successLoad,
        isLoad = _useOrderInfo.isLoad,
        onProgress = _useOrderInfo.onProgress;

    return {
      carCardImg: carCardImg,
      form: form,
      formEl: formEl,
      rules: rules,
      submit: submit,
      data: data,
      actionUrl: actionUrl,
      successLoad: successLoad,
      isLoad: isLoad,
      onProgress: onProgress
    };
  }
};