import { usePay } from "@/Hooks";
import { onUnmounted } from "vue";
export default {
  components: {},
  setup: function setup() {
    var right = require("assets/imgs/right.png");

    var _usePay = usePay(),
        doWxPay = _usePay.doWxPay,
        doQueryById = _usePay.doQueryById,
        orderInfo = _usePay.orderInfo,
        codeUrl = _usePay.codeUrl,
        timer = _usePay.timer;

    doQueryById();
    doWxPay();
    onUnmounted(function () {
      clearInterval(timer.value);
    });
    return {
      right: right,
      orderInfo: orderInfo,
      codeUrl: codeUrl
    };
  }
};