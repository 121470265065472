import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { watch, computed } from "vue";
import { useGetReserve } from "@/Hooks";
import { useStore } from "vuex";
import { nextTick } from "process";
export default {
  props: {
    //当前类型
    currentType: {
      require: true,
      type: Number
    },
    // 当前页码
    currentPage: {
      require: true,
      type: Number
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var searchState = computed(function () {
      return store.state.search;
    });
    var page = computed(function () {
      return props.currentPage;
    });

    var _useGetReserve = useGetReserve(),
        list = _useGetReserve.list,
        getReserve = _useGetReserve.getReserve;

    var emptyImg = require("assets/imgs/empty.png");

    var defaultImg = require("assets/imgs/5888.png");

    watch(searchState, function () {
      nextTick(function () {
        return getReserve(props, emit);
      });
    }, {
      deep: true,
      immediate: true
    });
    watch(page, function () {
      nextTick(function () {
        return getReserve(props, emit);
      });
    }, {
      deep: true
    });

    var buyClick = function buyClick(info) {
      emit("doBuy", info);
    };

    return {
      list: list,
      buyClick: buyClick,
      emptyImg: emptyImg,
      defaultImg: defaultImg
    };
  }
};