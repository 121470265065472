import "core-js/modules/es.string.repeat.js";
import search from "@/components/Search";
import stations from "@/components/Subscribe/Stations";
import PayPop from "components/Subscribe/PayPop";
import OrderInfo from "components/Subscribe/OrderInfo";
import realName from '@/components/Login/realName.vue';
import ClPop from "components/ClPop";
import { onMounted, ref, nextTick, watch, computed } from "vue";
import { useClPop, useMap, usePay, useOrderInfo } from "@/Hooks";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
export default {
  components: {
    search: search,
    stations: stations,
    ClPop: ClPop,
    PayPop: PayPop,
    OrderInfo: OrderInfo,
    realName: realName
  },
  setup: function setup() {
    var store = useStore();
    var currentIndex = ref(0);
    var currentPage = ref(1);
    var totalNum = ref(0); //分页器总数量

    var _useClPop = useClPop(),
        closePop = _useClPop.closePop,
        showPop = _useClPop.showPop;

    var typeList = ["距离最近", "最优惠的"];

    var chooseType = function chooseType(index) {
      currentIndex.value = index;

      if (store.state.position) {
        console.log(currentType);
        store.commit("setSearch");
      }
    }; // const { payVerify ,step, show, closePop,showPop } = usePay()


    var isRealName = computed(function () {
      return store.state.isRealName;
    });

    var payVerify = function payVerify(info) {
      if (isRealName.value) {
        store.commit("setChooseInfo", info);
        store.commit("setOrderType", 1);
        showPop(3);
      } else {
        showPop(2);
      }
    };

    var pageChange = function pageChange(page) {
      currentPage.value = page;
    };

    var getStationsInfo = function getStationsInfo(data) {
      console.log(data, "i".repeat(10));
      var _data$total = data.total,
          total = _data$total === void 0 ? 0 : _data$total;
      totalNum.value = total;
    };

    var doSearch = function doSearch() {
      store.commit("setSearch");
    };

    var currentType = computed(function () {
      return currentIndex.value + 1;
    }); // const store = useStore()

    onMounted(function () {
      useMap();
    });
    return {
      typeList: typeList,
      chooseType: chooseType,
      currentIndex: currentIndex,
      currentType: currentType,
      // showPop,
      closePop: closePop,
      pageChange: pageChange,
      currentPage: currentPage,
      getStationsInfo: getStationsInfo,
      totalNum: totalNum,
      payVerify: payVerify,
      doSearch: doSearch // step,

    };
  }
};